<template>
    <v-container>
        <v-col cols="12" class="mt-0 mt-md-16 mb-15">
            <h1 class="mb-10">What is Harmony Fan?</h1>

            <p>Harmony Fan has an intuitive, fast and easy-to-navigate platform.</p>

            <p>Hello! Welcome to your new favorite tool! You've probably already heard about Harmony Fan, but if you haven't, I invite you to meet us!</p>

            <p>If you are an active person in social networks, you should know that organization and personalization are important pillars so that your brand can correctly convey its message, regardless of whether you are a content creator, public figure, company or organization.</p>

            <p>Using Harmony will help you unify all the links to redirect your audience to the destination site according to their interest, all in one place, quickly, interactively and safely, acquiring statistical information from your users such as:</p>

            <ul style="list-style-type: none; padding: 0; margin: 0;">
                <li>Last activity</li>
                <li>Origin of traffic (website)</li>
                <li>Countries (Geographic location)</li>
                <li>Devices (Mobile/Desktop)</li>
                <li>Browsers (Chrome/Mozilla/Explorer/Opera)</li>
                <li>Language (Default browser language)</li>
                <li>Operating System (iOS/Microsoft)</li>
            </ul>
            <br>

            <p>In addition to knowing your audience, it is also important to have knowledge of remarketing strategies, and for this, we have Google Pixel + Facebook Pixel, which take advantage of the browsing data, obtained by cookies, from users who have already visited your Harmony.</p>

            <p>Why are we better than others similar platforms and all the competition?<br>
            Simply, we don't charge you for our Service, we pay you for Clicks and conversions in some of your platforms (Conditions may apply).</p>

            <p>Harmony Fan has an intuitive, fast and easy-to-navigate platform. Our Pro plan is FREE and includes special functions that boost your business and position your brand. You can get complete customization that stays true to your brand and turns leads. With Harmony Fan you have the complete analysis of the statistics of clicks, locations, devices, subscriptions, etc. so that you can carry out the best strategies while protecting your personal data, in addition to not seeing an ad on the page or on your link if you don't desire to.</p>

        </v-col>
    </v-container>
</template>

<script>
    export default {
        name: "About",
        metaInfo: {
            titleTemplate: '%s - Harmony Fan',
            title: '¿Qué es Harmony Fan?'
        }
    };
</script>